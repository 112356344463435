import React from "react";

import { sizes } from "../styles/styles";

export default ({ children, title, author, ...props }) => (
  <div
    className="publication"
    css={{ margin: `${sizes.paragraphMargin}px 0px` }}
  >
    <span css={{ fontWeight: "bolder" }}>{title}</span>
    {author && <span css={{ marginLeft: 0 }}> - {author}</span>}
    <div css={{ marginLeft: 15 }}>{children}</div>
  </div>
);
