// Constants and general style mix-ins

const colours = {
  primaryColor: "#333",
  lightAccentColor: "666",
  linkColor: "#4183C4",
  footer: "rgb(238,238,238)"
};

const mediaQueries = {
  mobile: "@media screen and (max-width: 640px)"
};

const fonts = {
  sans: "Helvetica, Arial, sans-serif"
};

const fontSizes = { primaryFontSize: 18 };

const sizes = {
  pageWidth: 740,
  paragraphMargin: 18
};

const centredWithMargins = css => ({
  ...css,
  marginLeft: "auto",
  marginRight: "auto",
  padding: "0 6px",
  maxWidth: sizes.pageWidth
});

const flexCol = {
  display: "flex",
  flexFlow: "column nowrap"
};

export {
  colours,
  mediaQueries,
  fonts,
  fontSizes,
  sizes,
  centredWithMargins,
  flexCol
};
