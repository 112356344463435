import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { renderAst } from "../components/rehype";
import Layout from "../components/layout"

const Page = props => {
    const {
        data: { markdownRemark: post }
    } = props;

    return (
        <Layout>
            <article>
                <Helmet>
                    <title>{post.frontmatter.title}</title>
                </Helmet>
                <header>
                    <h1 css={{ fontSize: "2em", border: "none", fontWeight: "normal" }}>
                        {post.frontmatter.title}
                    </h1>
                </header>
                <div className="entry">{renderAst(post.htmlAst)}</div>
            </article>
        </Layout>
    );
};

export const query = graphql`
  query BlogPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

export default Page;
