import React from "react";
import rehypeReact from "rehype-react";

import publication from "./Publication";
import convertToLink from "./convertToLink";

export const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        publication,
        a: convertToLink
    }
}).Compiler;
