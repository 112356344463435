import React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";

import Head from "./head";
import {
    centredWithMargins,
    colours,
    mediaQueries,
    flexCol
} from "../styles/styles";
import "../styles/global";

import avatar from "../pages/images/me@210.jpg";

const Layout = ({ data, children }) => {
    return (
        <div css={{ ...flexCol, minHeight: "100vh" }}>
            <Head siteMetadata={data.site.siteMetadata} />
            <div id="header" css={centredWithMargins()}>
                <div>
                    <header
                        css={{
                            paddingTop: 20,
                            display: "flex",
                            flexFlow: "row wrap",
                            alignItems: "center",
                            [mediaQueries.mobile]: { flexFlow: "column" }
                        }}
                    >
                        <Link to="/">
                            <img
                                src={avatar}
                                alt="Gordon's bearded avatar"
                                css={{ width: 70, borderRadius: "100%", marginRight: 10 }}
                            />
                        </Link>

                        <div>
                            <h1
                                css={{
                                    display: "inline-block",
                                    margin: 0,
                                    border: "none",
                                    letterSpacing: 1
                                }}
                            >
                                <Link to="/" css={{ color: "inherit" }}>
                                    {data.site.siteMetadata.title}
                                </Link>
                            </h1>
                            <p
                                css={{
                                    margin: 0,
                                    fontSize: "smaller",
                                    color: colours.lightAccentColor
                                }}
                            >
                                {data.site.siteMetadata.description}
                            </p>
                        </div>

                        <nav
                            css={{
                                flex: "1 0 auto",
                                textAlign: "right",
                                [mediaQueries.mobile]: {
                                    textAlign: "center",
                                    marginTop: 10
                                },
                                "& a": {
                                    display: "inline-block",
                                    whiteSpace: "pre",
                                    marginLeft: 10,
                                    color: colours.primaryColor,
                                    letterSpacing: 1,
                                    fontWeight: "lighter",
                                    "&:hover, &:active": { color: colours.linkColor }
                                }
                            }}
                        >
                            <Link to="/publications/">Publications</Link>
                            {/* <a href={data.site.siteMetadata.links.talks}>Talks</a> */}
                            <Link to="/projects/">Projects</Link>
                            <Link to="/teaching/hw-courses/">Teaching</Link>
                        </nav>
                    </header>
                </div>
            </div>

            <div id="content" css={centredWithMargins({ flex: "1 0 auto" })}>
                <div>{children}</div>
            </div>

            <footer
                css={{
                    backgroundColor: colours.footer,
                    marginTop: 50,
                    paddingTop: 20,
                    paddingBottom: 20,
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd"
                }}
            >
                <div css={centredWithMargins()}>© 2021 - Gordon Govan</div>
            </footer>
        </div>
    );
};

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        description
        links {
          talks
        }
      }
    }
  }
`;

export default (props) => (<StaticQuery query={query} render={(data) => (
    <Layout data={data} {...props} />
)}></StaticQuery>)
