import React from "react";
import Helmet from "react-helmet";

export default ({ siteMetadata }) => (
  <Helmet defaultTitle={siteMetadata.title} titleTemplate={`%s - Gordon Govan`}>
    <meta charset="utf-8" />
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
    />>
    <meta name="author" content={siteMetadata.title} />
    <meta name="description" content={siteMetadata.description} />>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    />>
    <meta 
      name="google-site-verification"
      content="NdBWrFvf1P9Fn1zp2q91F5rqCqQV4QPlvyJ5uVNm9VU" 
    />
    <html lang="en" />
  </Helmet>
);
