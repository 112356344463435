import React from "react";
import { Link } from "gatsby";

const isLocal = href => !/^[a-z]+:\/\//.test(href);

/**
 * Converts <a> elements to <Link> elements if they point to a local page
 */
export default ({ children, href, ...props }) =>
    isLocal(href) ? (
        <Link {...props} to={href}>
            {children}
        </Link>
    ) : (
            <a {...props} href={href}>
                {children}
            </a>
        );
