import { css } from "glamor";
import "glamor/reset";

import { colours, fontSizes, fonts } from "./styles";

// Styles applied globally to the page

css.global("html, body", {
  fontFamily: fonts.sans,
  color: colours.primaryColor,
  fontSize: fontSizes.primaryFontSize
});

css.global("h1", {
  borderBottom: "1px solid black",
  fontSize: "1.5em",
  fontWeight: "lighter",
  margin: "1em 0 0.5em"
});

css.global("h2", {
  paddingBottom: 2,
  borderBottom: "1px solid black"
});

css.global("a, a:hover, a:active", {
  color: colours.linkColor,
  textDecoration: "none"
});
